@font-face {
  font-family: 'agitateurs';
  src:  url(/fonts/agitateurs_04a8d637dd64138de49ae06b396fabbe.longcache.eot);
  src:  url(/fonts/agitateurs_04a8d637dd64138de49ae06b396fabbe.longcache.eot#iefix) format('embedded-opentype'),
    url(/fonts/agitateurs_ee6da5add4c83b7f69ba6a1ac32d5a93.longcache.ttf) format('truetype'),
    url(/fonts/agitateurs_6aed04c8b140e426d2e8a12ce5bd9f4c.longcache.woff) format('woff'),
    url(/fonts/agitateurs_a3b3c59ca2610dc19287ce2661632c7f.longcache.svg#agitateurs) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.adn {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'agitateurs' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.adn-stars:before {
  content: "\e914";
}
.adn-check-bold:before {
  content: "\ea39";
}
.adn-human-child:before {
  content: "\ea34";
}
.adn-gift:before {
  content: "\ea2f";
}
.adn-currency-eur:before {
  content: "\ea2d";
}
.adn-currency-eur-off:before {
  content: "\ea2e";
}
.adn-magnify:before {
  content: "\ea2c";
}
.adn-reload:before {
  content: "\ea2b";
}
.adn-lightbulb-on-outline:before {
  content: "\ea2a";
}
.adn-facebook-messenger:before {
  content: "\ea29";
}
.adn-share-variant:before {
  content: "\e90c";
}
.adn-bell:before {
  content: "\e90b";
}
.adn-tag-outline:before {
  content: "\e90a";
}
.adn-bookmark:before {
  content: "\e909";
}
.adn-flag-checkered:before {
  content: "\e908";
}
.adn-arrow-right-circle:before {
  content: "\e907";
}
.adn-alert-outline:before {
  content: "\e906";
}
.adn-emoticon-dead-outline:before {
  content: "\e905";
}
.adn-floppy:before {
  content: "\e904";
}
.adn-checkbox-marked-circle-outline:before {
  content: "\e903";
}
.adn-folder-multiple-image:before {
  content: "\e902";
}
.adn-clock:before {
  content: "\e901";
}
.adn-location-arrow:before {
  content: "\e900";
}
.adn-timer-sand-empty:before {
  content: "\ea91";
}
.adn-timer-sand-full:before {
  content: "\ea92";
}
.adn-shield-check-outline:before {
  content: "\ea82";
}
.adn-cancel:before {
  content: "\ea81";
}
.adn-twitter:before {
  content: "\ea80";
}
.adn-instagram:before {
  content: "\ea67";
}
.adn-facebook:before {
  content: "\ea68";
}
.adn-weather-sunny-alert:before {
  content: "\ea69";
}
.adn-weather-sunny:before {
  content: "\ea70";
}
.adn-weather-windy:before {
  content: "\ea71";
}
.adn-weather-snowy-rainy:before {
  content: "\ea72";
}
.adn-weather-pouring:before {
  content: "\ea73";
}
.adn-baby-face-outline:before {
  content: "\ea74";
}
.adn-wheelchair-accessibility:before {
  content: "\ea75";
}
.adn-party-popper:before {
  content: "\ea76";
}
.adn-eye-check-outline:before {
  content: "\ea77";
}
.adn-eye-off-outline:before {
  content: "\ea56";
}
.adn-set-center:before {
  content: "\ea57";
}
.adn-arrow-left-circle-outline:before {
  content: "\ea53";
}
.adn-arrow-right-circle-outline:before {
  content: "\ea52";
}
.adn-snowflake:before {
  content: "\ea50";
}
.adn-sport:before {
  content: "\ea43";
}
.adn-spotify:before {
  content: "\ea42";
  color: #1ed760;
}
.adn-deezer:before {
  content: "\ea41";
  color: #feaa2d;
}
.adn-youtube:before {
  content: "\ea40";
  color: #f00;
}
.adn-after-noon:before {
  content: "\ea36";
}
.adn-morning:before {
  content: "\ea35";
}
.adn-evening:before {
  content: "\ea37";
}
.adn-local-play:before {
  content: "\e910";
}
.adn-open-people:before {
  content: "\e911";
}
.adn-arrow-circle-up:before {
  content: "\e913";
}
.adn-clean_hands:before {
  content: "\e912";
}
.adn-colorize:before {
  content: "\e90f";
}
.adn-pdf:before {
  content: "\e90e";
}
.adn-download:before {
  content: "\e90d";
}
.adn-filter:before {
  content: "\ea90";
}
.adn-filter-outline:before {
  content: "\ea89";
}
.adn-trash-can-outline:before {
  content: "\ea88";
}
.adn-format-vertical-align-top:before {
  content: "\ea87";
}
.adn-link-off:before {
  content: "\ea86";
}
.adn-close-circle:before {
  content: "\ea85";
}
.adn-chevron-down:before {
  content: "\ea84";
}
.adn-calendar-range:before {
  content: "\ea83";
}
.adn-alert:before {
  content: "\ea25";
}
.adn-equalizer:before {
  content: "\ea1d";
}
.adn-fast-forward:before {
  content: "\ea78";
}
.adn-sync:before {
  content: "\ea58";
}
.adn-video:before {
  content: "\ea0a";
}
.adn-phone:before {
  content: "\ea0f";
}
.adn-email:before {
  content: "\ea12";
}
.adn-forum:before {
  content: "\ea0c";
}
.adn-map-marker:before {
  content: "\ea09";
}
.adn-message-outline:before {
  content: "\ea00";
}
.adn-message-processing:before {
  content: "\ea0b";
}
.adn-email-outline:before {
  content: "\ea08";
}
.adn-at:before {
  content: "\ea63";
}
.adn-plus:before {
  content: "\ea66";
}
.adn-plus-circle:before {
  content: "\ea18";
}
.adn-plus-circle-outline:before {
  content: "\ea65";
}
.adn-close:before {
  content: "\ea15";
}
.adn-pencil:before {
  content: "\ea1a";
}
.adn-link:before {
  content: "\ea33";
}
.adn-weekend:before {
  content: "\ea49";
}
.adn-clock-outline:before {
  content: "\ea01";
}
.adn-bullet-list:before {
  content: "\ea61";
}
.adn-emoticon-outline:before {
  content: "\ea24";
}
.adn-image:before {
  content: "\ea04";
}
.adn-upload:before {
  content: "\ea16";
}
.adn-phone-android:before {
  content: "\ea64";
}
.adn-watch:before {
  content: "\ea30";
}
.adn-night:before {
  content: "\ea32";
}
.adn-camera:before {
  content: "\ea05";
}
.adn-chevron-left:before {
  content: "\ea44";
}
.adn-chevron-right:before {
  content: "\ea45";
}
.adn-eye:before {
  content: "\ea59";
}
.adn-noon:before {
  content: "\ea31";
}
.adn-directions:before {
  content: "\ea02";
}
.adn-bike:before {
  content: "\ea21";
}
.adn-car:before {
  content: "\ea20";
}
.adn-walk:before {
  content: "\ea1f";
}
.adn-flower:before {
  content: "\ea47";
}
.adn-cart:before {
  content: "\ea11";
}
.adn-map:before {
  content: "\ea1e";
}
.adn-store:before {
  content: "\ea13";
}
.adn-arrow-left:before {
  content: "\ea0e";
}
.adn-menu-down:before {
  content: "\ea14";
}
.adn-arrow-right:before {
  content: "\ea51";
}
.adn-check:before {
  content: "\ea79";
}
.adn-menu:before {
  content: "\ea55";
}
.adn-account:before {
  content: "\ea26";
}
.adn-star:before {
  content: "\ea38";
}
.adn-star-outline:before {
  content: "\ea62";
}
.adn-check-circle:before {
  content: "\ea06";
}
.adn-delete:before {
  content: "\ea46";
}
.adn-heart:before {
  content: "\ea27";
}
.adn-heart-outline:before {
  content: "\ea28";
}
.adn-close-circle-outline:before {
  content: "\ea54";
}
.adn-home:before {
  content: "\ea0d";
}
.adn-lock:before {
  content: "\ea23";
}
.adn-web:before {
  content: "\ea10";
}
.adn-open-in-new:before {
  content: "\ea19";
}
.adn-power:before {
  content: "\ea17";
}
.adn-star-circle:before {
  content: "\ea1b";
}
.adn-account-multiple:before {
  content: "\ea22";
}
.adn-view-list:before {
  content: "\ea60";
}
.adn-magnify-plus-outline:before {
  content: "\ea03";
}
.adn-check-circle-outline:before {
  content: "\ea07";
}
.adn-beach-umbrella:before {
  content: "\ea48";
}
.adn-filter-list:before {
  content: "\e917";
}
.adn-awesome-sun:before {
  content: "\e915";
}
.adn-keyboard-arrow-down:before {
  content: "\e916";
}
.adn-flash_on:before {
  content: "\e919";
}
.adn-heart-ticket:before {
  content: "\e918";
}
.adn-bubbles2:before {
  content: "\e96d";
}
